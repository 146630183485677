<template>
  <section id="content">
    <div class="first">
      <div class="overlay"></div>
      <div class="container">
        <h2>Довезем до дома</h2>
        <h2>Трезвый водитель</h2>
        <a href="tel:+7 927 7454 232" class="phone">ТЕЛ +7 927 7454 232</a>
        <div class="actions_contact">
          <img src="../assets/logo.png" class="largus_logo" alt="largus logo">
          <div id="show_contact">
            <button v-on:click="showContacts">Заказать</button>
          </div>
          <div>
          </div>
        </div>
      </div>

    </div>
    <div class="desktop">
      <div class="overlay"></div>
      <div class="container">
        <p class="describe">Вегда выгоднее вызвать, чем управлять автомобилем на свой страх и риск.<br>
          Попасть на ДПС и лишиться прав не оправдано дорого!<br>
          Воспользуйтесь услугой трезвый водитель!<br>
          Управлять автомобилем в нетрезвом состоянии запрещено, не рискуйте!<br>
          Воспользуйтесь нашей услугой <span class="hidden">трезвый водитель в Самаре!<br>
        У нас выгодная цена на данную услугу<br>
        Перегоним Ваш автомобиль к Вам домой безопастно и быстро!<br>
        Мы поможем забрать Ваш автомобиль с автостоянки.</span></p>
      </div>
    </div>
    <div class="mobile">
      <div class="overlay"></div>
      <div class="container">
        <p class="describe">Вегда выгоднее вызвать, чем управлять автомобилем на свой страх и риск.<br>
          Попасть на ДПС и лишиться прав не оправдано дорого!<br>
          Воспользуйтесь услугой трезвый водитель!<br>
          Управлять автомобилем в нетрезвом состоянии запрещено, не рискуйте!<br>
        Воспользуйтесь нашей услугой <span class="hidden">трезвый водитель в Самаре!<br>
        У нас выгодная цена на данную услугу<br>
        Перегоним Ваш автомобиль к Вам домой безопастно и быстро!<br>
        Мы поможем забрать Ваш автомобиль с автостоянки.</span></p>
        <p class="blue-desc">С НАМИ ВЫГОДНЕЕ! <br>
        ЧЕМ БЕЗ НАС!</p>
        <p class="blue-desc">РАБОТАЕМ 24 часа !!!</p>
        <p class="drug-hour">УСЛУГА ДРУГ НА ЧАС</p>
        <p class="blue-desc">МИНИМАЛЬНАЯ ЦЕНА<br>
          ЗА УСЛУГУ ТРЕЗВЫЙ ВОДИТЕЛЬ<br>
          1500 руб (включен 1 час работы)</p>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Home',
  components: {},
  props: {
    msg: String
  },
  data: function () {
    let time = new Date();
    const cTime = time.toLocaleString()
    return {
      cTime
    };
  },
  methods: {
    showContacts() {
      const elem = document.getElementById('contacts')
      if (elem.classList.contains('hidden')) {
        elem.classList.remove('hidden')
      } else {
        elem.classList.add('hidden')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
.actions_contact
  position: relative
  z-index: 9
  font-size: 40px

.container
  max-width: 1200px
  margin: 0 auto
  z-index: 2

.overlay
  background-color: rgba(20, 20, 50, 0.5)
  position: absolute
  width: 100%
  height: 100%
  z-index: 1

#content
  display: grid
  grid-template-columns: 100%
  grid-template-rows: auto
  min-height: 100vh
  overflow-x: hidden

  div
    background-repeat: no-repeat
    background-size: cover
    background-position: center top

  .first
    background-image: url("../assets/car.jpg")
    background-position: center 30%
    width: 100%
    position: relative
    display: grid
    grid-template-rows: 1fr auto auto auto
    text-shadow: 0 0 2em rgba(238, 237, 242, 0.99)
    text-transform: uppercase
    font-size: calc(100vw * 0.02)

    h2
      color: rgb(205, 203, 208)
      font-family: "a_AntiqueTrady", sans-serif
      text-align: center
      letter-spacing: 0.1em
      padding-top: 0.5em

    .phone
      display: block
      color: #fdfffc
      font-family: "a_AssuanBrk", sans-serif
      text-decoration: none
      padding: 1em
      font-size: calc(100vw * 0.04)
      -webkit-text-stroke: 2px #f9700d
      text-shadow: 0 0 20px #f9700d

  .actions_contact
    width: calc(100%)
    display: grid
    grid-template-columns: auto 1fr
    padding: calc(100vw * 0.045) 0

    #show_contact
      text-align: center
      display: flex
      align-items: center
      justify-content: center

      button
        cursor: pointer
        font-family: "Tahoma", sans-serif
        font-weight: 700
        font-size: calc(100vw * 0.05)
        text-transform: uppercase
        color: #ffaf02
        letter-spacing: 0.01em
        background: rgba(25, 25, 25, .5)
        border: .03em solid #f9740d
        padding: 0 .3em
        transition: .2s ease all

        &:hover
          background: rgba(123, 0, 70, .85)
          -webkit-text-stroke: 1px #f9700d
          text-shadow: 0 0 20px #f9700d

    .largus_logo
      width: calc(100vw * 0.15)
      max-width: 165px

  .desktop
    background-image: url("../assets/test.jpg")
    width: 100%
    min-height: 50vh
    text-align: center
    position: relative
    display: flex
    flex-direction: column
    justify-content: center
    align-content: center

    .describe
      font-family: 'a_AssuanBrk', sans-serif
      color: #ff6600
      text-shadow: 1px 0 10px rgba(0, 0, 0, 0.8)
      font-size: calc(100vw * 0.03)
      padding: 2em 1.4em calc(100vw * 0.01) 1.4em
      line-height: 1.4em
      font-weight: 400
      -webkit-text-stroke: 1px #000


  .mobile
    display: flex
    background-image: url("../assets/mob-fon.jpg")
    width: 100%
    text-align: center
    position: relative
    flex-direction: column
    padding-bottom: 1em

    .describe
      display: none
      font-family: 'a_AssuanBrk', sans-serif
      color: #ff6600
      text-shadow: 1px 0 10px rgba(0, 0, 0, 0.8)
      font-size: calc(100vw * 0.03)
      padding: 2em 1.4em calc(100vw * 0.01) 1.4em
      line-height: 1.4em
      font-weight: 400
      -webkit-text-stroke: 1px #000

    .blue-desc
      color: #ccccff
      text-shadow: 1px 0 10px rgba(0, 0, 0, 0.8)
      font-size: calc(100vw * 0.03)
      line-height: 1.4em
      padding-top: 1em
      -webkit-text-stroke: 1px #000


    .drug-hour
      color: #ee020f
      text-shadow: 1px 0 8px rgba(0, 0, 0, 0.99)
      -webkit-text-stroke: 1px #000
      box-shadow: 1px 0 15px 2px rgba(0, 0, 0, 0.95)
      font-size: calc(100vw * 0.03)
      font-weight: 500
      margin: 1em

@media all and (max-width: 1200px)
  .container
    max-width: 800px

@media all and (max-width: 768px)
  #content
    div
      background-position: center top

    .first
      background-position: center top
      grid-template-rows: 1fr auto calc(100vw * 0.2)
      min-height: 60vh
      font-size: calc(100vw * 0.045)

      .container
        display: grid
        grid-template-rows: 1fr 1fr 1fr auto

      h2
        background-color: transparent

      .phone
        display: block
        font-size: calc(100vw * 0.07)

    .actions_contact
      position: absolute
      z-index: 2
      width: calc(100%)
      display: grid
      grid-template-columns: auto 1fr
      top: calc(60vh * 1)
      height: calc(100vw * 0.05)
      background: #7b0046
      border-top: 0.1em solid #f9740e
      border-bottom: 0.1em solid #f9740e
      padding: 0

      #show_contact
        margin-top: calc(100vw * -0.05)

        button
          font-size: calc(100vw * 0.12)

      .largus_logo
        margin-top: calc(100vw * -0.05)

    .desktop
      display: none

    .mobile
      .describe
        display: block
        font-size: calc(100vw * 0.06)
      .blue-desc
        font-size: calc(100vw * 0.058)
      .drug-hour
        font-size: calc(100vw * 0.058)
        transform: rotate(-20deg)
        margin: 2em 1em;


@media all and (max-width: 600px)
  #content
    div
      background-position: center top

    .first
      min-height: 40vh

    .actions_contact
      top: calc(40vh * 0.95)


</style>
