<template>
  <section class="contacts hide-scrollbar hidden" id="contacts">
    <button v-on:click="showContacts" class="contacts_close">Закрыть</button>
    <div class="head">
      <h2>Звоните по телефону <a href="tel:+79277454232">8 9277 454 232</a></h2>
      <h3><span>Услуга</span><br><span>трезвый водитель</span></h3>
    </div>
    <form class="" @submit.prevent="onSubmit">
      <div class="input-group">
        <label for="contactFrom"><span>Откуда забрать:</span>
          <input type="text" v-model="contactFrom" id="contactFrom" aria-describedby="place-from">
        </label>
      </div>
      <div class="input-group">
        <label for="contactTo"><span>Куда перегнать:</span>
          <input type="text" v-model="contactTo" id="contactTo" aria-describedby="place-to" required>
        </label>
      </div>
      <div class="input-group">
        <label for="contactTime"><span>Время:</span>
          <input type="text" v-model="contactTime" id="contactTime" aria-describedby="in-time">
        </label>
      </div>
      <div class="input-group">
        <label for="contactPhone"><span>Ваш телефон:</span>
          <input type="tel" v-phone v-model="contactPhone" id="contactPhone"
                 placeholder="+7 (999) 999-9999" aria-describedby="basic-tel" required>
        </label>
      </div>
      <!--div class="input-group">
        <label for="contactMessage"><span>Пожелание:</span>
              <input type="text" id="contactMessage" v-model="contactMessage" placeholder="">
        </label>
      </div-->
      <div class="sending">
        <button type="submit" class="" v-on:click="gtag_report_conversion(window.location.pathname)">Оставить заявку
        </button>
      </div>
      <div v-if="successSend" class="sending">
        <h4 class="text-success">Ваше сообщение успешно отправлено!</h4>
      </div>
      <h3>Цена на услуги 1300 руб</h3>
    </form>
    <h4>За городом или в дальных районах цена договорная</h4>
  </section>
</template>

<script>
import '../plugins/phone-mask'
import axios from 'axios'

export default {
  name: "Contacts",
  data: function () {
    return {
      successSend: false,
      contactPhone: null,
      contactMessage: null,
      contactFrom: null,
      contactTo: null,
      contactTime: null
    };
  },
  mounted() {
    axios
        .get('https://api.telegram.org/bot1517230781:AAFTZMPZSe7BLwRO0Fqb2IGuurgWbuYSidk/getMe')
        .then(response => {
          (this.info = response);
          console.log(this.info)
        });
  },
  methods: {
    showContacts() {
      const elem = document.getElementById('contacts')
      if (elem.classList.contains('hidden')) {
        elem.classList.remove('hidden')
      } else {
        elem.classList.add('hidden')
      }
    },
    onSubmit() {
      let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      let crd = 'Заказчик не дал разрешения'
      let time = new Date();

      function success(pos) {
        crd = pos.coords;
      }

      function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`);
      }

      navigator.geolocation.getCurrentPosition(success, error, options);

      setTimeout(() => {
        let contactForm = 'Заказ ' + time.toLocaleString()
            + " откуда: " + this.contactFrom
            + " / куда: " + this.contactTo
            + " / время: " + this.contactTime
            + " / телефон: " + this.contactPhone
        // + " / сообщение: " + this.contactMessage
        if (crd !== 'Заказчик не дал разрешения') {
          contactForm += "    Метоположение широта: " + crd.latitude
              + " долгота: " + crd.longitude
              + " плюс-минус " + crd.accuracy + ' метров.'
        } else {
          contactForm += "    Метоположение " + crd
        }

        console.log(contactForm)
        axios.get('https://api.telegram.org/bot1517230781:AAFTZMPZSe7BLwRO0Fqb2IGuurgWbuYSidk/sendMessage?chat_id=-1001168330368&text=' + contactForm)
        const data = {
          "receiver": "5tuWOpLgiKhss9PHSASwTA==",
          "min_api_version": 1,
          "sender": {
            "name": "Taxi Largus",
            "avatar": "https://taxilargus.ru/templates/landkit/images/logo-1.png"
          },
          "tracking_data": "tracking data",
          "type": "text",
          "text": contactForm
        }
        const viber_headers = {
          'Content-Type': 'application/json',
          'Authorization': 'JWT fefege...'
        }
        axios.post('https://chatapi.viber.com/pa/send_message', data, {headers: viber_headers})
        this.successSend = true
        this.contactPhone = null
        this.contactMessage = null
        setTimeout(() => {
          this.successSend = false
        }, 3000)
      }, 1000)

    }
  }
}
</script>

<style scoped lang="sass">
.contacts_close
  cursor: pointer
  width: 100%
  background-color: #7b0046
  color: #ffffff
  font-size: calc(100vw * 0.03)
  text-transform: uppercase
  text-align: center

.contacts
  background: #f3f0e7
  width: 100%
  min-height: 100vh
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  z-index: 11
  display: grid
  grid-template-rows: calc(100vw * 0.04) calc(100vw * 0.2) auto auto
  overflow-y: scroll

  .head
    background-image: url("../assets/contact_fon.jpg")
    background-repeat: no-repeat
    background-position: center 70%
    background-size: cover
    width: 100%
    display: flex
    justify-content: space-between
    flex-direction: column
    text-transform: uppercase

    h2
      color: #f0f0e6
      font-size: calc(100vw * 0.03)
      text-shadow: 0 0 10px #dd2d1e
      text-align: right
      padding: calc(100vw * 0.03) calc(100vw * 0.05) 0 0

      a
        color: #f0f0e6

    h3
      color: #f8bb15
      font-size: calc(100vw * 0.03)
      text-shadow: 0 0 10px #dd2d1e
      text-align: left
      padding: calc(100vw * 0.02)

      span
        background-color: #000000
        padding: calc(100vw * 0.01)
        line-height: 1.5em


  form
    font-family: "Tahoma", sans-serif
    text-transform: uppercase
    max-width: 1200px
    margin: 0 auto
    padding: 1em
    width: 90%

    label
      width: calc(100%)
      font-size: 1.6em
      padding: 0 0 .4em 0
      display: flex
      justify-content: space-between

      span
        font-family: "a_Assuan", sans-serif
        color: #f1823b

      input
        color: #f0f0e6
        background-color: #aa797f
        border: none
        box-shadow: 0 0 .01em .1em #e8f148
        font-size: 1.2em
        padding: calc(100vw * 0.005) calc(100vw * 0.01)

    .sending
      padding: .2em 0
      text-align: right

    button
      cursor: pointer
      font-size: 2.6em
      padding: .2em 1em
      color: #ebda06
      background-color: #74433f
      border: none
      box-shadow: 0 0 .01em .1em #e8f148

    .text-success
      font-size: calc(100vw * 0.03)

    h3
      color: #9a574e
      text-align: right
      font-size: calc(100vw * 0.028)

  h4
    text-align: center
    font-size: calc(100vw * 0.03)
    text-transform: uppercase
    text-shadow: 0 0 10px #f71d13


@media all and (max-width: 767px)
  .contacts
    grid-template-rows: auto calc(100vw * 0.4) 1fr auto
    grid-template-columns: repeat(auto-fit, minmax(200px, auto))
    .head
      h2
        font-size: 1.4em
        padding: calc(100vw * 0.01) calc(100vw * 0.1) 0 0

        a
          white-space: nowrap

      h3
        font-size: 1.4em

    form
      padding: 0
      margin: 1em .5em
      width: calc(100% - 1em)

      label
        font-size: 1.6em
        flex-direction: column

        span
          text-align: left
          width: 100%

        input
          font-size: 1.2em
          padding: .1em .2em
          box-shadow: 0 0 1px 2px

      .sending
        text-align: center

      button
        font-size: calc(100vw * 0.08)
        padding: calc(100vw * 0.005) calc(100vw * 0.03)
        margin: 0 auto

      h3
        font-size: calc(100vw * 0.06)
        padding: calc(100vw * 0.025) calc(100vw * 0.03)
        text-align: center

    h4
      font-size: 1.2em
      text-align: center

  .contacts_close
    font-size: 2em
</style>
