<template>
  <div id="app">
    <section id="header"></section>
    <Home msg="Welcome to Your Vue.js App"/>
    <Contacts />
  </div>
</template>

<script>
import Home from './components/Home.vue'
import Contacts from "./components/Contacts";

export default {
  name: 'App',
  components: {
    Contacts,
    Home
  }
}
</script>

<style lang="sass">
  @font-face
    font-family: 'a_AssuanBrk'
    font-style: normal
    font-weight: 400
    src: local('a_AssuanBrk'), local('a_AssuanBrk-Medium'),
    url(../src/assets/fonts/a_assuanbrk.woff) format('woff'),
    url(../src/assets/fonts/a_assuanbrk.ttf) format('truetype')
  @font-face
    font-family: 'a_AntiqueTrady'
    font-style: normal
    font-weight: 400
    src: local('a_AntiqueTrady'), local('a_AntiqueTrady-Medium'),
    url(../src/assets/fonts/a_AntiqueTrady.ttf) format('truetype')
  @font-face
    font-family: 'a_AvanteBs'
    font-style: normal
    font-weight: 400
    src: local('a_AvanteBs'), local('a_AvanteBs-Medium'),
    url(../src/assets/fonts/a_AvanteBs.ttf) format('truetype')
  @font-face
    font-family: 'a_Assuan'
    font-style: normal
    font-weight: 400
    src: local('a_Assuan'), local('a_Assuan-Medium'),
    url(http://allfont.ru/cache/fonts/a_assuan_542a7e75d34754e3943c73f50d028207.woff) format('woff'),
    url(http://allfont.ru/cache/fonts/a_assuan_542a7e75d34754e3943c73f50d028207.ttf) format('truetype')
  *
    margin: 0
    padding: 0
    outline: none
  /* Применяем max-width для замещаемых элементов и контролов формы. */
  img, video, audio, canvas, input, select, button, progress
    max-width: 100%

  /* Заставляем поля с типом file и submit переносить текст */
  input[type="file"], input[type="submit"]
    white-space: pre-wrap

  /* Чиним прогрессбар и поле-ползунок */
  progress, input[type="range"]
    width: 100%

  /* Фиксим поля с типом Number в Firefox */
  @supports (--moz-appearance: none)
    input[type="number"]
      width: 100%

  .fit-grid > *
    min-width: 0

  /* Применяем max-width к замещаемым элементам и контролам формы */
  .fit-grid img,
  .fit-grid video,
  .fit-grid audio,
  .fit-grid canvas,
  .fit-grid input,
  .fit-grid select,
  .fit-grid button,
  .fit-grid progress
    max-width: 100%

  /* Заставляем поля с типом file и submit переносить текст */
  .fit-grid input[type="file"],
  .fit-grid input[type="submit"]
    white-space: pre-wrap

  /* Чиним прогрессбар и поле-ползунок */
  .fit-grid progress,
  .fit-grid input[type="range"]
    width: 100%

  /* Фиксим поля с типом Number в Firefox */
  @supports (--moz-appearance: none)
    .fit-grid input[type="number"]
      width: 100%

  #app
    font-family: 'a_AssuanBrk', arial, sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
  body
    overflow-x: hidden !important
  .hidden
    display: none !important
  /* On Chrome */
  .hide-scrollbar::-webkit-scrollbar
    display: none

  /* For Firefox and IE */
  .hide-scrollbar
    scrollbar-width: none
    -ms-overflow-style: none



</style>
